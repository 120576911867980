import React from "react";
import loadable from "@loadable/component";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import {
  hero,
  heroImage,
  secondaryHeroTwoCols,
  productOverview,
  productsWithLinks,
  footer,
} from "../../../data/appetize/handhelds";
import { enterpriseSupport } from "../../../data/spoton-contacts";

const SimpleHero = loadable(() =>
  import("../../../components-v2/Sections/Hero/SimpleHero")
);
const LargeImageFullWidth = loadable(() =>
  import("../../../components-v2/Sections/LargeImageFullWidth")
);
const SecondaryHeroTitleTwoCols = loadable(() =>
  import("../../../components-v2/Sections/Hero/SecondaryHeroTitleTwoCols")
);
const ProductOverview = loadable(() =>
  import("../../../components-v2/Sections/ProductOverview")
);
const ProductsWithLinksPlainBackground = loadable(() =>
  import("../../../components-v2/Sections/ProductsWithLinksPlainBackground")
);
const FooterHero = loadable(() =>
  import("../../../components-v2/Sections/Hero/FooterHero")
);

const Handhelds = () => {
  return (
    <Layout className="non-fixed-width" {...enterpriseSupport}>
      <SEO
        loadChat={false}
        title="Handheld Point of Sale (POS) System With Mobile Terminals | SpotOn"
        description="Manage inventory, sales & customers all-in-one place while on the go. Our handheld POS systems are ready to enhance your business & the customer experience."
        cannonical="https://www.spoton.com/enterprise/handhelds/"
      />
      <SimpleHero className="mt-32 lg:mt-40" {...hero} />

      <LargeImageFullWidth className="mt-16 lg:mt-20" {...heroImage} />

      <SecondaryHeroTitleTwoCols
        className="mt-20 lg:mt-36"
        {...secondaryHeroTwoCols}
      />

      <ProductOverview
        className="mt-20 lg:mt-36"
        {...productOverview}
        noMainTitle
        offsetText
        flipItems
      />

      <ProductsWithLinksPlainBackground
        className="mt-20 lg:mt-36"
        {...productsWithLinks}
      />

      <FooterHero className="my-20 lg:my-36" {...footer} />
    </Layout>
  );
};

export default Handhelds;
