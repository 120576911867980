export const hero = {
  title: "Handheld point-of-sale (POS) system for your enterprise",
  subTitle: "Handhelds",
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/handhelds/demo",
  },
};

export const heroImage = {
  imageName: "products-handhelds-hero.png",
};

export const secondaryHeroTwoCols = {
  title: "Increase guest convenience and staff mobility",
  subtext:
    "Equip your staff to take the POS on-the-go with handheld and mobile options. A variety of screen sizes, accompanying cases and all-in-one enclosures, hand straps, and belt hooks make it easier to serve customers from anywhere.",
  image: {
    imageName: "products-handhelds-secondary-hero-2.png",
    mobileImageName: "products-handhelds-secondary-hero-2-mobile.png",
    title: "We’re dedicated to your success",
    subtext: "HANDHELDS",
  },
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/handhelds/demo",
  },
};

export const productOverview = {
  subSections: [
    {
      title: "Increase sales with on-the-go handheld POS",
      subtext:
        "Available for both iOS and Android devices, our handheld POS runs on the same intuitive, image-driven platform as the rest of your SpotOn enterprise point-of-sale solutions. Give your staff everything they need, including robust peripheral support with barcode scanners, cases, and Bluetooth printers. With order-only or full payment options, you can checkout guests more quickly, from anywhere.",
      imageName: "products-handhelds-product-1.png",
      cta: {
        title: "Learn more",
        target: "/enterprise/handhelds/demo",
      },
    },
  ],
};

export const productsWithLinks = {
  title: "Built on a true omnichannel ecosystem",
  products: [
    {
      title: "Point-of-Sale",
      description:
        "Reduce wait times with a user-friendly POS solution that processes orders and payments fast, even when offline.",
      linkTo: "/enterprise/pos-terminals",
      imageName: "products-kiosk.png",
    },
    {
      title: "Kiosks",
      description:
        "Give your guests direct control over their ordering experience as you speed up order fulfillment and improve your bottom line.",
      linkTo: "/enterprise/kiosks",
      imageName: "products-kiosk-2.png",
    },
    {
      title: "Kitchen Displays",
      description:
        "Instantly transmit all POS orders to a centralized device for preparation, even if your internet connection goes down.",
      linkTo: "/enterprise/kitchen-displays",
      imageName: "products-kitchen-display.png",
    },
  ],
};

export const footer = {
  title: "Book a consultation",
  subtext:
    "See for yourself how our enterprise cloud POS and management platform can transform the guest experience, provide valuable business insights, and deliver ROI to your enterprise.",
  label: "ONE PARTNER.",
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/handhelds/demo",
  },
};
